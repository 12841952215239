import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    TemplateRef
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker/bs-datepicker.config';
import { AppComponent } from '../../app.component';
import { _3sService } from '../../core/api/3s/3s.service';
import { VehicleModelService } from '../../core/api/vehicle/vehicle-model.service';
import { VehicleService } from '../../core/api/vehicle/vehicle.service';
import { OasValidators } from '../../core/validator/oas-validators';
import { ErrorType, Vehicle, VehicleBrand, VehicleModel } from '../../shared/entities';
import { Outlet } from './../../shared/entities';
import { CountryConfiguration } from '../../country-configuration';
import { AuthService } from '../../auth/auth.service';
import { AlertConfirmComponent } from '../../shared/alert/alert-confirm.component';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { AlertWarningComponent } from '../../shared/alert/alert-warning.component';
import { StyleSwitcherService } from '../../core/style-switcher.service';
import { CountryConfigurationService } from '../../../configuration/country-configuration.service';
import { dataAnalytics } from '../../shared/data-analytics';
import { TracyService } from '../../shared/tracy.service';

@Component({
    selector: 'oas-vehicle-form',
    templateUrl: './vehicle-form.component.html',
    styleUrls: ['./vehicle-form.component.scss'],
})
export class VehicleFormComponent implements OnInit {
    @Input() public vehicle: Vehicle;
    @Input() public outlet: Outlet;
    @Input() public isCourtesyVehicle: false;
    @Input() public submitted: boolean;
    @Input() public searchButtonEnabled: boolean;
    @Input() public hideErrorPopup: boolean;
    @Input() public formClass: string;
    @Input() public showLabelsOverInput: boolean;
    @Input() public hideDataStep: boolean;
    @Input() public onlyAllowVehicleThatHaveSameBrandOfTheWebsite: boolean;
    @Input() public tcModalClosed? = true;

    @Output() private selectedModelChange = new EventEmitter<VehicleModel>();
    @Output() private vinChange = new EventEmitter<string>();
    @Output() private fiscalCodeChange = new EventEmitter<string>();

    @Output() private errorsWithExternalSystem = new EventEmitter<boolean>();
    @Output() private vehicleNotFound = new EventEmitter<boolean>();
    @Output() private vehicleEmitter = new EventEmitter<Vehicle>();
    // @Output() private remoteKeyReadEmitter = new EventEmitter<boolean>();
    @ViewChild('insertFiscalCode') insertFiscalCode: TemplateRef<any>;
    @ViewChild('plateInfoContent') plateInfoContent: TemplateRef<any>;
    @ViewChild('vehicleInformationNotAvailableDescriptionTmpl')
    vehicleInformationNotAvailableDescriptionTmpl: TemplateRef<any>;
    @ViewChild('warningTitleTmpl') warningTitleTmpl: TemplateRef<any>;
    @ViewChild(AlertConfirmComponent) private alertConfirmWarning: AlertConfirmComponent;
    @ViewChild(AlertWarningComponent) private alertWarning: AlertWarningComponent;

    @ViewChild('warningChangeWebsiteToTheAppropriateBrandContentTmpl')
    private warningChangeWebsiteToTheAppropriateBrandContentTmpl: TemplateRef<any>;
    @ViewChild('warningChangeWebsiteToTheAppropriateBrandTitle')
    private warningChangeWebsiteToTheAppropriateBrandTitle: TemplateRef<any>;

    public vehicles: VehicleModel[];
    public vehicleForm: UntypedFormGroup;
    public searchCarControl = new UntypedFormControl();
    public maxLength = 17;
    public minLength = 17;
    public isCatalogLoaded = false;
    public bsConfig: Partial<BsDatepickerConfig>;
    public isData = false;
    public isAutomatic = false;
    public hiddenButtons: any;
    public step: number;
    public vehicleInformationNotAvailableTitle: string;
    public vehicleInformationNotAvailableDescription: string;
    
    private previousVin: string;
    private previousPlate: string;

    /**
     * Values isManual:
     * 0 -> pag 1
     * 1 -> pag 2 Manual search data for vehicle
     * 2 -> pag 2 Show fields form for insert information manually
     *
     **/
    public isManual = 0;

    public countryConfiguration: CountryConfiguration;

    constructor(
        private vehicleModelService: VehicleModelService,
        private formBuilder: UntypedFormBuilder,
        private vehicleService: VehicleService,
        private appComponent: AppComponent,
        public authService: AuthService,
        private router: Router,
        private styleSwitcherService: StyleSwitcherService,
        countryConfigurationService: CountryConfigurationService,
        private tracyService: TracyService
    ) {
        this.countryConfiguration = countryConfigurationService.getCountryConfiguration();
        this.setDataAnalytics();
    }

    public ngOnInit() {
        if (this.countryConfiguration.vehicleFormSmartRegistrationType === 'MANUAL') {
            this.isManual = 1;
        } else {
            this.isData = false;
        }
        this.bsConfig = Object.assign(
            {},
            {
                containerClass: 'theme-dark-blue',
                locale: this.countryConfiguration.locale,
                rangeInputFormat: this.countryConfiguration.dateInputRangeFormat,
                dateInputFormat: 'DD/MM/YYYY',
            }
        );

        if (this.vehicle) {
            this.previousVin = this.vehicle.vin;
            this.previousPlate = this.vehicle.plate;
        } else {
            this.vehicle = { model: {} };
        }

        const plateValidators = [Validators.required, OasValidators.newPlate];
        let dateToSet: Date;
        if (this.vehicle.matriculationDate) {
            dateToSet = new Date(this.vehicle.matriculationDate);
        }
        if (this.countryConfiguration.showFiscalNumberVehicleForm) {
            this.vehicleForm = this.formBuilder.group({
                plate: [
                    { value: this.vehicle.plate, disabled: this.isCourtesyVehicle ? this.isCourtesyVehicle : false },
                    plateValidators,
                ],
                fiscalNumber: ['', [Validators.required]],
                searchCarControl: [
                    {
                        value: this.getLabel(this.vehicle.model),
                        disabled: this.vehicle && this.vehicle.id ? true : false,
                    },
                    Validators.required,
                ],
                vin: [this.vehicle.vin, [Validators.required, OasValidators.vin]],
                modelId: [this.vehicle.model.id],
                matriculationDate: [dateToSet],
            });
        } else {
            this.vehicleForm = this.formBuilder.group({
                plate: [
                    { value: this.vehicle.plate, disabled: this.isCourtesyVehicle ? this.isCourtesyVehicle : false },
                    plateValidators,
                ],
                searchCarControl: [
                    {
                        value: this.getLabel(this.vehicle.model),
                        disabled: this.vehicle && this.vehicle.id ? true : false,
                    },
                    Validators.required,
                ],
                vin: [this.vehicle.vin, [Validators.required, OasValidators.vin]],
                modelId: [this.vehicle.model.id],
                matriculationDate: [dateToSet],
            });
        }
        this.vehicleModelService.getAll().then(vehicles => {
            vehicles.forEach(vehicle => {
                vehicle.modelLabel = this.getLabel(vehicle);
            });
            this.vehicles = vehicles;
            this.isCatalogLoaded = true;

            if (this.vehicle.plate) {
                this.onBlurPlate(this.vehicle.plate);
            }
        });
    }

    private getLabel(vehicleModel: VehicleModel): string {
        if (vehicleModel.modelLabel) {
            let label = vehicleModel.modelLabel;

            if (vehicleModel.productionStartYear) {
                label += ` (${vehicleModel.productionStartYear})`;
            }

            return label;
        }
        return '';
    }

    public typeaheadOnSelect(e: TypeaheadMatch): void {
        this.changeVehicleModel(e.item);
    }

    public isCar() {
        if (this.vehicles) {
            const vehicleModelId = this.vehicleForm.get('modelId').value;
            const vehicleModelSelected = this.vehicles.find(vehicleModel => vehicleModel.id === vehicleModelId);
            return !vehicleModelSelected || (vehicleModelSelected && vehicleModelSelected.type === 'CAR');
        }
        return true;
    }

    private changeVehicleModel(model: VehicleModel): void {
        if (model) {
            this.vehicleForm.get('modelId').setValue(model.id);
            this.selectedModelChange.emit(model);
        }
    }

    public onBlurVin(value: string): void {
        if (this.vehicleForm.get('vin').valid && value !== this.vehicle.vin && value !== this.previousVin) {
            this.vinChange.emit(value);
            this.previousVin = value;
            let loaderNumber = 0;

            if (this.outlet && this.outlet.dcomEnabled) {
                this.appComponent.loading = true;
                loaderNumber++;
                this.vehicleService.checkUniqueVin(value, this.outlet.id).subscribe(response => {
                    loaderNumber--;
                    if (response) {
                        this.vehicleForm.get('vin').setErrors({ oasVinValidator: true });
                    }
                    if (loaderNumber === 0) {
                        this.appComponent.loading = false;
                    }
                });
            }

            if (!this.vehicle.id) {
                this.appComponent.loading = true;
                loaderNumber++;

                this.vehicleService
                    .getVehicleInformationFromVin(value)
                    .then(vehicleInformation => {
                        const vehicle = vehicleInformation.payload;
                        const errors = vehicleInformation.errors;
                        loaderNumber--;
                        if (errors.length === 0) {
                            if (vehicle || vehicle.model == null) {
                                if (vehicle.model) {
                                    this.vehicleForm.get('searchCarControl').setValue(this.getLabel(vehicle.model));
                                    this.changeVehicleModel(vehicle.model);
                                }
                                if (vehicle.plate) {
                                    this.previousPlate = vehicle.plate;
                                    this.vehicleForm.get('plate').setValue(vehicle.plate);
                                }
                                if (vehicle.matriculationDate) {
                                    this.vehicleForm
                                        .get('matriculationDate')
                                        .setValue(new Date(vehicle.matriculationDate));
                                }
                                if (!vehicle || vehicle.model == null) {
                                    this.step = 2;
                                    this.alertConfirmWarning.contentTmpl = this.plateInfoContent;
                                    this.alertConfirmWarning.show();
                                    this.hidden();
                                } else {
                                    this.emitVehicle(vehicle);
                                    if (!this.hideDataStep) {
                                        this.isData = true;
                                    }
                                }
                            }
                        } else {
                            this.showErrorForVehicleInformationUnavailable(errors[0].message);
                            this.vehicleNotFound.next(true);
                        }

                        if (loaderNumber === 0) {
                            this.appComponent.loading = false;
                        }
                    })
                    .catch(() => {
                        loaderNumber--;
                        if (loaderNumber === 0) {
                            this.appComponent.loading = false;
                        }
                        this.alertConfirmWarning.contentTmpl = this.plateInfoContent;
                        this.alertConfirmWarning.show();
                        this.hidden();
                    });
            }
        }
    }

    public onBlurPlate(value: string): void {
        if (this.isManual === 1) {
            this.isManual = 2;
        } else if (
            this.vehicleForm.get('plate').valid &&
            value !== this.vehicle.plate &&
            value !== this.previousPlate &&
            this.isManual == 0
        ) {
            this.previousPlate = value;
            this.isData = false;
            if (this.countryConfiguration.createVehicleFiscalCode) {
                if (this.authService.isCustomer()) {
                    this.getFiscalCode();
                }
            } else {
                this.appComponent.loading = true;
                this.vehicleService
                    .getVehicleInformationFromPlate(value)
                    .then(vehicleInformation => {
                        const vehicle = vehicleInformation.payload;
                        const errors = vehicleInformation.errors;
                        if (errors.length === 0 && vehicle != null) {
                            if (vehicle) {
                                if (vehicle.model) {
                                    this.vehicleForm.get('searchCarControl').setValue(this.getLabel(vehicle.model));
                                    this.changeVehicleModel(vehicle.model);
                                }

                                if (vehicle.vin && vehicle.model) {
                                    this.vinChange.emit(vehicle.vin);
                                    this.previousVin = vehicle.vin;
                                    this.vehicleForm.get('vin').setValue(vehicle.vin);
                                }

                                if (vehicle.matriculationDate) {
                                    this.vehicleForm
                                        .get('matriculationDate')
                                        .setValue(new Date(vehicle.matriculationDate));
                                }
                            }

                            this.step = 2;
                            this.emitVehicle(vehicle);
                            if (!this.hideDataStep) {
                                this.isData = true;
                            }
                        } else {
                            if(errors && errors.length) {
                                if (errors[0].errorType === <ErrorType>'warning') {
                                    this.step = 1;
                                    this.showAlertWarning();
                                } else {
                                    this.showErrorForVehicleInformationUnavailable(errors[0].message);
                                }
                            } 
                            this.vehicleNotFound.next(true);
                        }

                        this.appComponent.loading = false;
                    })
                    .catch(() => {
                        this.appComponent.loading = false;
                        this.step = 1;
                        this.showAlertWarning();
                        this.errorsWithExternalSystem.next(true);
                    });
            }
        }
    }

    public getFiscalCode() {
        this.appComponent.openModal('oas-fiscal-code', this.insertFiscalCode, 'your-vehicles-modal modal-lg');
    }

    private async searchVehicleByPlateAndFiscalCode() {
        this.appComponent.loading = true;
        this.fiscalCodeChange.next(this.vehicleForm.get('fiscalNumber').value);
        this.vehicleService
            .getVehicleInformationFromPlateAndFiscalCode(
                this.getVehiclePlate(),
                this.vehicleForm.get('fiscalNumber').value
            )
            .then(responseInformation => {
                const errors = responseInformation.errors;
                if (errors.length === 0) {
                    const vehicle = responseInformation.payload;

                    if (vehicle.vin && vehicle.model) {
                        this.vinChange.emit(vehicle.vin);
                        this.vehicleForm.get('vin').setValue(vehicle.vin);
                    }
                    if (vehicle.model) {
                        this.vehicleForm.get('searchCarControl').setValue(this.getLabel(vehicle.model));
                        this.changeVehicleModel(vehicle.model);
                    }
                    if (vehicle.matriculationDate) {
                        this.vehicleForm.get('matriculationDate').setValue(vehicle.matriculationDate);
                    }

                    this.emitVehicle(vehicle);
                    this.step = 2;
                    if (!this.hideDataStep) {
                        this.isData = true;
                    }
                } else {
                    if (errors[0].errorType === <ErrorType>'warning') {
                        this.step = 1;
                        this.showAlertWarning();
                    } else {
                        this.showErrorForVehicleInformationUnavailable(errors[0].message);
                    }
                    this.vehicleNotFound.next(true);
                }
                this.appComponent.loading = false;
            })
            .catch(err => {
                this.step = 1;
                this.showAlertWarning();
                this.appComponent.loading = false;
                this.errorsWithExternalSystem.next(true);
            });
    }

    private showAlertWarning() {
        if (!this.hideErrorPopup) {
            this.alertConfirmWarning.contentTmpl = this.plateInfoContent;
            this.alertConfirmWarning.show();
            this.hidden();
        }
    }

    private showErrorForVehicleInformationUnavailable(message: string) {
        if (!this.hideErrorPopup) {
            this.vehicleInformationNotAvailableDescription = message;
            this.alertWarning.titleTmpl = this.warningTitleTmpl;
            this.alertWarning.contentTmpl = this.vehicleInformationNotAvailableDescriptionTmpl;
            this.alertWarning.show();
        }
    }

    public searchVehicleOnChangeInput(value: string) {
        if (!this.searchButtonEnabled) {
            this.searchVehicle(value);
        }
    }

    public searchButtonDisabed() {
        return this.vehicleForm.invalid;
    }

    public searchVehicle(value: string) {
        if(this.tcModalClosed) {
            const isPlateValid = this.vehicleForm.get('plate').valid;
            const isVinValid = this.vehicleForm.get('vin').valid;
            const isManual = this.isManual === 1;
    
            switch (this.countryConfiguration.vehicleFormSmartRegistrationType) {
                case 'PLATE_AND_FISCAL_NUMBER':
                    if (isPlateValid && this.vehicleForm.get('fiscalNumber').valid && !isManual) {
                        this.searchVehicleByPlateAndFiscalCode();
                    } else if (isVinValid && isManual) {
                        this.onBlurVin(this.vehicleForm.get('vin').value);
                    } else if (this.searchButtonEnabled) {
                        this.markAsTouched('fiscalNumber');
                        this.markAsTouched('plate');
                        this.markAsTouched('vin');
                    }
                    break;
                case 'PLATE':
                    if (isPlateValid && !isManual) {
                        this.onBlurPlate(this.getVehiclePlate());
                    } else if (isVinValid && isManual) {
                        this.onBlurVin(this.vehicleForm.get('vin').value);
                    }
                    break;
                case 'MANUAL':
                default:
                    if (isVinValid && isPlateValid && isManual) {
                        this.onBlurVin(this.vehicleForm.get('vin').value);
                    } else {
                        this.markAsTouched('plate');
                        this.markAsTouched('vin');
                    }
                    break;
            }
        }
    }

    private markAsTouched(control: string): void {
        this.vehicleForm.controls[control].markAsTouched();
    }

    public retryGetVehicleInformation() {
        this.previousPlate = '';
        this.vehicleForm.get('plate').setValue('');
        this.isManual = 0;
    }

    public manualForm(value: number) {
        if (value == 0) {
            this.isData = false;
            this.isManual = 1;
        }
    }

    public back() {
        if (this.countryConfiguration.vehicleFormSmartRegistrationType === 'MANUAL') {
            this.isManual = 1;
        } else {
            this.isManual = 0;
        }
        this.vehicleForm.get('vin').setValue('');
        this.vehicleForm.get('plate').setValue('');
        let model: VehicleModel;
        this.changeVehicleModel(model);
        this.isData = false;
    }

    public retry(step: number) {
        switch (step) {
            case 1:
                this.previousPlate = '';
                this.vehicleForm.get('plate').setValue('');
                this.isManual = 0;
                swal.close();
                break;
            case 2:
                swal.close();
                this.previousVin = '';
                this.vehicleForm.get('vin').setValue('');
                break;
        }
    }

    public manual(step: number) {
        switch (step) {
            case 1:
                if (this.isManual === 0) {
                    this.isData = false;
                    this.isManual = 1;
                }
                swal.close();
                break;
        }
    }

    public goTo() {
        swal.close();
        this.router.navigate(['../../quick-appointment']);
    }

    public hidden() {
        this.hiddenButtons = document.getElementsByClassName('swal2-actions');
        this.hiddenButtons[0].style.display = 'none';
    }

    public getVehiclePlate(): string {
        return this.vehicleForm.get('plate').value;
    }

    public emitVehicle(vehicle: Vehicle) {
        let canEmitVehicle = true;
        if (this.onlyAllowVehicleThatHaveSameBrandOfTheWebsite) {
            const currentBrand = this.styleSwitcherService.brand.getValue().toUpperCase() as VehicleBrand;

            if (
                vehicle.model.brand !== currentBrand &&
                !(currentBrand === <VehicleBrand>'BMW' && vehicle.model.brand === <VehicleBrand>'BMW_I')
            ) {
                this.vehicleForm.get('plate').setErrors({ plateOfNotAllowedBrand: true });
                canEmitVehicle = false;
                this.alertWarning.titleTmpl = this.warningChangeWebsiteToTheAppropriateBrandTitle;
                this.alertWarning.contentTmpl = this.warningChangeWebsiteToTheAppropriateBrandContentTmpl;
                this.alertWarning.show();
            }
        }

        if (canEmitVehicle) {
            this.vehicleEmitter.emit(vehicle);
        }
    }
    private setDataAnalytics() {
        this.tracyService.setPageInformationIntoDataAnalytics(
            dataAnalytics.page.pageID.lOasGuestVehicleLicensePlate,
            dataAnalytics.page.variant.real
        );
        this.tracyService.setUserInformationIntoDataAnalytics('na');

        this.tracyService.sendPageView(dataAnalytics.referenceName.guestVehicleLicensePlate);
    }
}
